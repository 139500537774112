/** HomepageMain
 *  ------------------------------------------------------------------------------------------------
 *  First content for home page
**/

.HomepageMain {
  text-align: center;

  & .Wrap {
    position: relative;
  }

  & .HomepageMain__image {
    /* no top margin on mobile because we want to meet the cta from above */
    /* --flow-space: 0; */
  }
}

@media (--edge-to-edge-ends) {

  .HomepageMain {

    & .HomepageMain__image {
      --flow-space: var(--bsu);
    }
  }
}

/** More technical layout at larger sizes
 *  ------------------------------------------------------------------------------------------------
 *  The primary section adds padding at desktop sizes to accomodate the image
 *  which is positioned absolutely.
 *  We use the same sizing as for normal weighted two column content.
 *  -
 *  Everything is relative to a .Wrap with maximum layout max-width so that things don't spread too
 *  faron wide screens.
**/

@media (--column-break) {

  .HomepageMain {
    text-align: left;
  }

  .HomepageMain__primary {
    padding-right: var(--column-light);
  }

  .HomepageMain__image {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 25%;
    width: var(--column-light);
    height: 100%; /* force height to match text */

    /* no top margin on mobile because we want to meet the cta from above */
    --flow-space: 0;

    & .Wrap,
    & .PaddedImage {
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & .PaddedImage__padder {
      display: none;
    }
  }
}
