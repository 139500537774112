/** Horizontal scrolling reels on mobile
 *  ------------------------------------------------------------------------------------------------
**/

.HorizontalReel,
.HorizontalReelGeneric {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: var(--colour-secondary) var(--colour-background-subtle);
  background-color: var(--colour-background-subtle);

  & > .InlineImage .Wrap,
  & .HorizontalReel__ignore-me > .InlineImage .Wrap {
    padding: 0;
  }
}

.js .HorizontalReelGeneric.js--draggable {
  cursor: grab;
}

.js .HorizontalReelGeneric.dragging {
  cursor: grabbing;
}

.js .HorizontalReelGeneric.dragging * {
  /* prevents text select */
  user-select: none;
}

/* otherwise firefox drags image.... */
.HorizontalReelGeneric img {
  pointer-events: none;
}

.HorizontalReel__ignore-me {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HorizontalReel > *,
.HorizontalReelGeneric > *,
.HorizontalReel .HorizontalReel__ignore-me > *,
.HorizontalReelGeneric .HorizontalReel__ignore-me > * {
  flex-shrink: 0;
}

.HorizontalReel > * + *,
.HorizontalReel .HorizontalReel__ignore-me > * + *,
.HorizontalReel > .InlineImage + .InlineImage,
.HorizontalReelGeneric > * + *,
.HorizontalReelGeneric .HorizontalReel__ignore-me > * + *,
.HorizontalReelGeneric > .InlineImage + .InlineImage {
  --flow-space: 0;

  margin-left: var(--bsu);
}

@media (--column-break) {

  .HorizontalReel {
    display: block;
    background: transparent;

    & > .InlineImage .Wrap,
    & .HorizontalReel__ignore-me > .InlineImage .Wrap {
      padding-left: var(--bsu);
      padding-right: var(--bsu);
    }
  }

  .HorizontalReel > * + *,
  .HorizontalReel .HorizontalReel__ignore-me > * + *,
  .HorizontalReel > .InlineImage + .InlineImage {
    --flow-space: var(--bsu);

    margin-left: auto;
  }
}


/** Scrollbar styling
 *  ------------------------------------------------------------------------------------------------
**/
.HorizontalReel::-webkit-scrollbar,
.HorizontalReelGeneric::-webkit-scrollbar {
  height: var(--bsu-xs);
}

.HorizontalReel::-webkit-scrollbar-track,
.HorizontalReelGeneric::-webkit-scrollbar-track {
  background-color: var(--colour-background-subtle);
}

.HorizontalReel::-webkit-scrollbar-thumb,
.HorizontalReelGeneric::-webkit-scrollbar-thumb {
  background-color: var(--colour-background-subtle);
  background-image: linear-gradient(var(--colour-background-subtle) 0, var(--colour-background-subtle) calc(var(--bsu-xs) * 0.25), var(--colour-secondary) calc(var(--bsu-xs) * 0.25), var(--colour-secondary) calc(var(--bsu-xs) * 0.75), var(--colour-background-subtle) calc(var(--bsu-xs) * 0.75));
}

.HorizontalReel--no-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/** we override some image styles in horizontal reels
 *  ------------------------------------------------------------------------------------------------
**/
.HorizontalReel > img,
.HorizontalReel .HorizontalReel__ignore-me > img,
.HorizontalReelGeneric > img,
.HorizontalReelGeneric .HorizontalReel__ignore-me > img {
  display: block;
  height: 550px;
  max-height: 80vh;
  width: auto;
  -webkit-user-drag: none;
}

.HorizontalReel > .InlineImage .PaddedImage img,
.HorizontalReel .HorizontalReel__ignore-me > .InlineImage .PaddedImage img,
.HorizontalReelGeneric > .InlineImage .PaddedImage img,
.HorizontalReelGeneric .HorizontalReel__ignore-me > .InlineImage .PaddedImage img {
  position: static;
  display: block;
  height: 550px;
  max-height: 80vh;
  width: auto;
  object-fit: fill;
}

.HorizontalReel > .InlineImage .PaddedImage__padder,
.HorizontalReel .HorizontalReel__ignore-me > .InlineImage .PaddedImage__padder,
.HorizontalReelGeneric > .InlineImage .PaddedImage__padder,
.HorizontalReelGeneric .HorizontalReel__ignore-me > .InlineImage .PaddedImage__padder {
  display: none;
}

@media (--column-break) {

  .HorizontalReel > img,
  .HorizontalReel .HorizontalReel__ignore-me > img {
    display: block;
    height: auto;
    max-height: none;
  }

  .HorizontalReel > .InlineImage .PaddedImage img,
  .HorizontalReel .HorizontalReel__ignore-me > .InlineImage .PaddedImage img {
    position: absolute;
    display: block;
    height: 100%;
    max-height: none;
    width: 100%;
    object-fit: cover;
  }

  .HorizontalReel > .InlineImage .PaddedImage__padder,
  .HorizontalReel .HorizontalReel__ignore-me > .InlineImage .PaddedImage__padder {
    display: block;
  }
}
