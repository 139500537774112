/** HomepagePartnerships
 *  ------------------------------------------------------------------------------------------------
**/

.HomepagePartnerships {
  /*
    this places it higher than the normal <section>s
    ensures that the image overflow from above is hidden!
  */
  z-index: var(--z-home-partnerships);
  text-align: center;

  & .SylSection__content {
    background-color: var(--colour-background-body);
  }
}

.HomepageKitchens + .HomepagePartnerships {
  padding-top: 0;
}

/* .HomepagePartnerships__column--one {
  display: none;
} */

.HomepagePartnerships__column--three .PaddedImage {
  margin-left: auto;
  margin-right: auto;
}


/** Column layout
 *  ------------------------------------------------------------------------------------------------
**/

.HomepagePartnerships__columns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  max-width: var(--layout-max);
  max-width: calc(var(--layout-max) + calc(var(--bsu) * 2));

  & .HomepagePartnerships__column {
    flex-basis: 100%;
  }
}

.HomepagePartnerships__column--two {
  margin-top: var(--bsu-l);
}

@media (--edge-to-edge-ends) {

  .HomepagePartnerships__column--two {
    margin-top: var(--bsu);
  }

  .HomepagePartnerships__columns {
    padding-left: var(--bsu);
    padding-right: var(--bsu);
  }
}

@media (--column-break) {

  .HomepagePartnerships {
    text-align: left;
    padding: var(--bsu-l) 0;
    position: relative;

    /* hide images that can peek from above */
    &::before {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: var(--bsu-l);
      background-color: var(--colour-background-body);
    }
  }

  .HomepageKitchens + .HomepagePartnerships {
    padding-top: 0;
  }

  .HomepagePartnerships__column--three .PaddedImage {
    margin-left: 0;
  }

  .HomepagePartnerships__columns {
    padding-left: 0;
    padding-right: var(--bsu);

    & .HomepagePartnerships__column--one {
      flex-basis: 30%;
      display: block;

      & .Wrap {
        padding-left: 0;
      }
    }

    & .HomepagePartnerships__column--two {
      flex-basis: 50%;
      max-width: var(--layout-readable);
      max-width: calc(var(--layout-readable) + calc(var(--bsu) * 2));
      margin-top: 0;
    }

    & .HomepagePartnerships__column--three {
      flex-basis: 20%;
      padding-left: var(--bsu);
      margin-top: 0;
    }
  }
}
