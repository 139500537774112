.HorizontalReelGeneric--home-kitchens {
  align-items: stretch;
  padding: var(--bsu-s) 0;
}

.HomepageKitchens__kitchen {
  width: 85vw;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  color: var(--colour-primary);
  max-width: 600px;

  & h3 {
    font-size: var(--s1);
  }

  & .Wrap {
    padding: 0;
  }

  & strong {
    display: block;
    transition: color var(--time-transition) ease;
  }

  &:hover,
  &:focus {
    color: var(--colour-primary);

    & strong {
      color: var(--colour-highlight);
    }
  }
}

@media (--edge-to-edge-ends) {

  .HomepageKitchens__kitchen {
    width: 45vw;
  }
}

.HomepageKitchens__kitchen__details {
  flex: 1;
  background-color: var(--colour-background-body);
  padding: var(--bsu) var(--bsu-s);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  --flow-space: var(--bsu-s);

  & p {
    text-wrap: balance;
  }
}
